import { 
   CLINIC_LOGIN,
   CLINIC_LOGIN_RES,
   FILE_DOWNLOAD,
   FILE_DOWNLOAD_RES,
   FILE_SHARE,
   FILE_SHARE_RES,
   INBOX_LIST,
   INBOX_LIST_RES,
   MESSAGE_HISTORY,
   MESSAGE_HISTORY_RES,
   USER_INFO,
   USER_INFO_RES
   } from './chat.types'

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Akhil Francis<akhil@trillium-technologies.com>

 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * clinicSave-Action w.r.t CLINIC_SAVE(type) in the redux
 * @description - Action used to call the clinic save API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const clinicLogin = (data) => ({
  type: CLINIC_LOGIN,
  payload: data,
})

export const clinicLoginResponse = (res) => ({
  type: CLINIC_LOGIN_RES,
  payload: res,
})
export const inboxList = (params) => ({
  type: INBOX_LIST,
  payload: params,
})

export const inboxListRes = (res) => ({
  type: INBOX_LIST_RES,
  payload: res,
})
export const messageHistory = (params) => ({
  type: MESSAGE_HISTORY,
  payload: params,
})

export const messageHistoryResponse = (res) => ({
  type: MESSAGE_HISTORY_RES,
  payload: res,
})
export const fileShare = (params) => ({
  type: FILE_SHARE,
  payload: params,
})

export const fileShareResponse = (res) => ({
  type: FILE_SHARE_RES,
  payload: res,
})
export const fileDownload = (params) => ({
  type: FILE_DOWNLOAD,
  payload: params,
})

export const fileDownloadResponse = (res) => ({
  type: FILE_DOWNLOAD_RES,
  payload: res,
})
export const userInfo = (params) => ({
  type: USER_INFO,
  payload: params,
})

export const userInfoResponse = (res) => ({
  type: USER_INFO_RES,
  payload: res,
})
