import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as UtilsAjax } from 'Utils'
import { objectToUrl } from 'Utils/ajax-utils'
import environment from '../../environments/environment'
import{
 CLINIC_LOGIN,
 FILE_DOWNLOAD,
 FILE_SHARE,
 INBOX_LIST,
 MESSAGE_HISTORY,
 USER_INFO
 } from './chat.types'
import {
  clinicLoginResponse,
  inboxListRes,
  messageHistoryResponse,
  fileShareResponse,
  fileDownloadResponse,
  userInfoResponse
} from './chat.actions'
// import { Apis } from 'Redux/APIs'
const { baseUrl } = environment
const chatEpic = {}

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
 chatEpic.loginApi = (action$) =>
 action$.pipe(
   ofType(CLINIC_LOGIN),
   switchMap((action) =>
     ajax({
       headers: {
         'application-id': 2,
         'Content-Type': 'application/json',
       },
       url: `${baseUrl}/trillium-af-clinic-service/v1/authentication/clinic/user/login`,
       method: 'POST',
       body: action.payload,
     }).pipe(
       map(
         (response) => clinicLoginResponse(response),
         // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
       ),
     ),
   ),
 )
 chatEpic.inboxListApi = (action$) =>
  action$.pipe(
    ofType(INBOX_LIST),
    switchMap((action) =>
    UtilsAjax({
        url: objectToUrl(`${baseUrl}/trillium-af-clinic-service/v1/chat/clinic/inbox`,action.payload),
        method: 'GET',
      }).pipe(
        map(
          (response) => inboxListRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
  chatEpic.messageHistoryApi = (action$) =>
  action$.pipe(
    ofType(MESSAGE_HISTORY),
    switchMap((action) =>
    UtilsAjax({
        url: objectToUrl(`${baseUrl}/trillium-af-clinic-service/v1/chat/clinic/messages/list`,action.payload),
        method: 'GET',
      }).pipe(
        map(
          (response) => messageHistoryResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
  chatEpic.shareFileInchat = (action$) =>
  action$.pipe(
    ofType(FILE_SHARE),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        url: `${baseUrl}/trillium-af-clinic-service/v1/chat/clinic`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => fileShareResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

  chatEpic.fileDownloadApi = (action$) =>
  action$.pipe(
    ofType(FILE_DOWNLOAD),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/trillium-af-clinic-service/v1/chat/download?filePath=${action.payload}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) =>fileDownloadResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

  chatEpic.getUserInfoApi = (action$) =>
  action$.pipe(
    ofType(USER_INFO),
    switchMap((action) =>
    UtilsAjax({
        url:`${baseUrl}/trillium-af-clinic-service/v1/user/logged-in/info/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => userInfoResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
  
export default chatEpic
