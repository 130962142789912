import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {clinicLogin,userInfo} from 'Redux/Chat/chat.actions'
import { chatSocket } from 'Utils/web-socket'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import TrilliumLogo from '../../assets/icons/trillium-logo.svg';
import LoginChatImg from '../../assets/icons/login-chat-img.svg';
import './style.css';

/**
 * @fileOverview This component manages the login page the Authentication based on clinic id,username and password and redirecting the user to the dashboard,
 * @component
 * @author  Akhil Francis <akhil@tensaw.email>
 * @author  Akhil Francis <ridhik@tensaw.email>
 * @example
 * return(
 * <LoginForm/>
 * )
 */

const LoginForm = (props) => {
	const [values,setValues]=useState({
          userName:"",
		  password:""
	})

	const [formError,setformError]=useState({
		userName:false,
		password:false
	})

	const handleSubmit = (e)=>{
		e.preventDefault()
      props.clinicLogin({
		clinicId:0,
		userName:values.userName,
		password:values.password
	  })
	}

useEffect(()=>{
   if(
	props.chatData.loginResponse&&
	props.chatData.loginResponse.response){
	let code=props.chatData.loginResponse.response.responseCode
	if(code===0){
	let data = props.chatData.loginResponse.response.data
	localStorage.setItem('accessToken',data.accessToken)
	props.userInfo(data.userId)
   }else{
	setformError({
		userName:true,
		password:true	
	})
  }
}
},[props.chatData.loginResponse])

useEffect(()=>{
	if(
	 props.chatData.userInfo&&
	 props.chatData.userInfo.response){
	 let code=props.chatData.userInfo.response.responseCode
	 if(code===0){
	 let data = props.chatData.userInfo.response.data
	 localStorage.setItem('user_data',JSON.stringify(data.loginInfo))
     localStorage.setItem("internalUsers",JSON.stringify(data.memoryCash.internalUserList))
     window.location.href="/chat"
	}else{

   }
 }
 },[props.chatData.userInfo])

useEffect(()=>{
	let userData = JSON.parse(localStorage.getItem('user_data'))
	if(userData){
	chatSocket.emit('clinic_logoff', {
		roleId:userData.roleId,
		userId: userData.userId,
		clinicId: userData.clinicId,
		userName:userData.firstName
      })
	}
	// localStorage.clear()
},[])

	return (
		<div className='clinicLogin'>
			<header className='clinicLogin__header'>
				<div className='clinicLogin__header__bar'>
					<a
						href='https://trillium.health/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img
							src={TrilliumLogo}
							alt='trillium-health-logo'
							className='clinicLogin__header__logo'
						/>
					</a>
				</div>
			</header>

			<main className='clinicLogin__main'>
				<section className='clinicLogin__section'>
					<div className='clinicLogin__grid'>
						<div className='clinicLogin__grid__col'>
							<img alt='logincoverpicture' src={LoginChatImg} />
						</div>

						<div className='clinicLogin__grid__col clinicLogin__grid__col--form'>
							<form onSubmit={handleSubmit} className='clinicLogin__form'>
								<Typography variant='h4' className='fw-500 text-center p-sm'>
									Trillium Internal Chat Support Tool
								</Typography>
								<TextField
									margin='normal'
									error={formError.userName}
									required
									label='Username'
									type='text'
									variant='outlined'
									className='clinicLogin__inputField'
									value={values.userName}
									onChange={(e)=>setValues({...values,userName:e.target.value})}
								/>

								<TextField
									margin='normal'
									error={formError.password}
									helperText={formError.password&&'Invalid Username or Password'}
									required
									type='password'
									label='Password'
									variant='outlined'
									className='clinicLogin__inputField'
									value={values.password}
									onChange={(e)=>setValues({...values,password:e.target.value})}
									autoComplete="off"
								/>

								<Button
									type='submit'
									variant='contained'
									color='primary'
									className='clinicLogin__submitBtn'
									disabled={values.userName&&values.password?false:true}
								>
									{/* <span>Login</span> */}
									<Typography variant='h6'>Login</Typography>
								</Button>
							</form>
						</div>
					</div>
				</section>
			</main>
			<div className='clinicLogin__copyright'>
				<Typography
					variant='caption'
					align='center'
					color='textSecondary'
					display='block'
				>
					Copyright &copy; {new Date().getFullYear()}-
					{new Date().getFullYear() + 1} trillium.health
				</Typography>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	chatData: state.chat,
  })
  
  const mapDispatchToProps = (dispatch) => ({
	clinicLogin: (values) => dispatch(clinicLogin(values)),
	userInfo:(values) => dispatch(userInfo(values)),
  })
  LoginForm.propTypes = {
	clinicLogin: PropTypes.func,
	userInfo: PropTypes.func,
  }
  export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
