import {
  CLINIC_LOGIN_RES, FILE_DOWNLOAD, FILE_DOWNLOAD_RES, INBOX_LIST_RES,
  MESSAGE_HISTORY_RES,USER_INFO_RES
 } from './chat.types'
/**
 * @fileOverview Manages the response of action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  loginResponse: null,
  inboxList:null,
  messageHistory:null,
  file:null,
  fileType: null,
  userInfo:null
}

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLINIC_LOGIN_RES: {
      return { ...state, ...{ loginResponse: action.payload } }
    }
    case INBOX_LIST_RES: {
      return { ...state, ...{ inboxList: action.payload } }
    }
    case MESSAGE_HISTORY_RES: {
      return { ...state, ...{ messageHistory: action.payload } }
    }
    case FILE_DOWNLOAD: {
      return { ...state, ...{ fileType: action.payload } }
    }
    case FILE_DOWNLOAD_RES: {
      return { ...state, ...{ file: action.payload } }
    }
    case USER_INFO_RES: {
      return { ...state, ...{ userInfo: action.payload } }
    }
    
    default:
      return state
  }
}
export default chatReducer
