/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const CLINIC_LOGIN = 'CLINIC_LOGIN'
export const CLINIC_LOGIN_RES = 'CLINIC_LOGIN_RES'
export const INBOX_LIST = 'INBOX_LIST'
export const INBOX_LIST_RES = 'INBOX_LIST_RES'
export const MESSAGE_HISTORY = 'MESSAGE_HISTORY'
export const MESSAGE_HISTORY_RES = 'MESSAGE_HISTORY_RES'
export const FILE_SHARE = 'FILE_SHARE'
export const FILE_SHARE_RES = 'FILE_SHARE_RES'
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD'
export const FILE_DOWNLOAD_RES = 'FILE_DOWNLOAD_RES'
export const USER_INFO = 'USER_INFO'
export const USER_INFO_RES = 'USER_INFO_RES'

