import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import store from 'Redux/store'
import { Provider } from 'react-redux'
import { createTheme } from '@material-ui/core/styles';
import App from './App';

const theme = createTheme({
	typography: {
		fontFamily: `Inter, sans-serif`,
	},
	palette: {
		primary: {
			// main: '#3cd970',
			// main: '#41dd75',
			main: '#1ACE7F',
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
		<Provider store={store}>
        <App />
      </Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
