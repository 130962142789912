import React from 'react';
import { BrowserRouter, Switch, Redirect,Route } from 'react-router-dom'
import { PrivateRoute } from 'Utils/private-route'
import LoginForm from './containers/Login';
import Chat from './containers/Chat';
import './GlobalStyles.css';

function App() {
	return (
		<div>
		 <BrowserRouter>
        <React.Suspense fallback={'loading'}>
          <Switch>
            <Route
              path="/login"
              component={LoginForm}
            />
			<PrivateRoute
              path="/chat"
              component={Chat}
            />
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
		</div>
	);
}

export default App;
