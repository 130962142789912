import { combineEpics } from 'redux-observable'
import chatEpic from './Chat/chat.epic'

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
    chatEpic.loginApi,
    chatEpic.inboxListApi,
    chatEpic.messageHistoryApi,
    chatEpic.shareFileInchat,
    chatEpic.fileDownloadApi,
    chatEpic.getUserInfoApi
    )
export default rootEpic
