import React,{useState,useEffect,useRef} from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {messageHistory} from 'Redux/Chat/chat.actions'
import { chatSocket } from 'Utils/web-socket'
import {
	fileShare,
	fileDownload
  } from 'Redux/Chat/chat.actions'
import DoctorAvatar from '../../assets/icons/doctor-avatar.svg';
import NurseAvatar from '../../assets/icons/nurse-avatar.svg';
import FrontDeskAvatar from '../../assets/icons/frontdesk-avatar.svg';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import MessageSentIcon from '../../assets//icons/msg-sent-icon.svg';
import MessageReadIcon from '../../assets//icons/msg-read-icon.svg';
import MessageDeliveredIcon from '../../assets//icons/msg-delivered-icon.svg';
import FileIcon from '../../assets//icons/file-icon.svg';
import ImageIcon from '../../assets//icons/image-icon.svg';
import PdfIcon from '../../assets//icons/pdf-icon.svg';
import TextIcon from '../../assets//icons/text-icon.svg';
import VideoIcon from '../../assets//icons/video-icon.svg';
import WordDocIcon from '../../assets//icons/worddoc-icon.svg';
import DownloadIcon from '../../assets//icons/download-icon.svg';
import DownloadIconBlue from '../../assets//icons/download-icon-blue.svg';
import CloseIconRounded from '../../assets//icons/close-icon-rounded.svg';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import { TimestamptoTime } from 'Helpers/TimeDateTimestamp'
import { dateTommddyyy } from 'Helpers/TimeDateTimestamp'
import { months } from 'Helpers/json'
/**
 * @fileoverview ChatBox component that is placed inside "Chat" Tab option
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author  Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <ChatBox />
 * )
 */

function ChatBox(props) {
	let userData = JSON.parse(localStorage.getItem('user_data'))
	const [messages,setMessages]=useState([])
	const [typeValue, setTypeValue] = useState('')
	const [isScrollerAtBottom, setIsScrollerAtBottom] = useState(false)
	const [startOne, setStart] = useState(1)
	const [chatLoader,setChatLoader]=useState(true)
	const [selectedFile, setSelectedFile] = useState({})
	const [assignEl, setAssignEl] = useState(null)
	 const [closeEl, setCloseEl] = useState(null)
	const [internalUsers,setInternalUsers] = useState(JSON.parse(localStorage.getItem("internalUsers")))
	const [selectedUser, setselectedUser] = useState(null)
	const [userInfo,setUserInfo]=useState(null)
	const assignElOpen = Boolean(assignEl)
    const assignElId = assignElOpen ? 'simple-popover' : undefined

	const closeElOpen = Boolean(closeEl)
    const closeElId = closeElOpen ? 'simple-popover' : undefined

	const handleAssignElClick = (event) => {
		setAssignEl(event.currentTarget)
	  }
	
	  const handleAssignElClose = () => {
		setAssignEl(null)
	  }
	  const handleOpenClick = (event) => {
		setCloseEl(event.currentTarget)
	  }
	
	  const handleCloseClick = () => {
		setCloseEl(null)
	  }

	  const handleUser = (x) => {
		setselectedUser(x)
	  }
    useEffect(() => {
		chatSocket.removeListener('user_conversation', messageHandler)
		chatSocket.removeListener('user_message_status', messageStatusHandler)
		chatSocket.removeListener('user_session_assign', userAssignHandler)
		chatSocket.on('user_session_assign', userAssignHandler)
		chatSocket.on('user_conversation', messageHandler)
		chatSocket.on('user_message_status', messageStatusHandler)
		return () => {
		  chatSocket.removeListener('user_conversation', messageHandler)
		  chatSocket.removeListener('user_message_status', messageStatusHandler)
		  chatSocket.removeListener('user_session_assign', userAssignHandler)
		}
	  }, [props.userInfo, messages])

	  const messagesEndRef = useRef(null)
	  const messageContainerRef = useRef()
	  const messageContainerTargeted = messageContainerRef.current


  const scrollToBottom = (delayInMilliseconds) => {
    setTimeout(function () {
      messagesEndRef.current.scrollIntoView({})
    }, delayInMilliseconds)
  }
  
  useEffect(() => {
    const msgContainerTargeted = messageContainerRef.current

    if (
      msgContainerTargeted.offsetHeight + msgContainerTargeted.scrollTop >=
      msgContainerTargeted.scrollHeight
    ) {
      setIsScrollerAtBottom(true)
    } else {
      setIsScrollerAtBottom(false)
    }

    if (isScrollerAtBottom == true) {
      scrollToBottom()
    }
  }, [messages])

  const scrollToMiddle = () => {
    if (messageContainerTargeted.scrollTop == 0) {
      messageContainerTargeted.scrollBy({
        top: 400,
        behavior: 'smooth',
      })
    } else return
  }
  const handleScroll = (e) => {
    let x = props.userInfo
    let element = e.target

    /* condition: to check whether the scroll is at the bottom or not 'onScroll' action */
    if (
      messageContainerTargeted.offsetHeight +
        messageContainerTargeted.scrollTop >=
      messageContainerTargeted.scrollHeight
    ) {
      setIsScrollerAtBottom(true)
      // console.log('issaat the bottom at the second function ooyy')
    } else {
      setIsScrollerAtBottom(false)
    }

    if (element.scrollTop === 0) {
		props.messageHistory({
			conversationSessionId:props.userInfo.conversationSessionId,
			start:startOne,
			limit:19
		})
    }
  }


  const messageStatusHandler = (data) => {
    let messagesUpdated = []
    messages.forEach((x) => {
      if (
        x.fromUserId === userData.userId &&
        x.conversationId === data.conversationId
      ) {
        messagesUpdated.push({
          ...x,
          messageStatus: data.messageStatus,
        })
      } else {
        messagesUpdated.push(x)
      }
    })
    setMessages(messagesUpdated)
  }
  const sendFiles = (edata) => {
    const data = new FormData()
    data.append('file', selectedFile[0])
    data.append(
      'messageRequestJsonString',
      JSON.stringify({ ...edata, message: null }),
    )
    if (Object.keys(selectedFile).length > 0) {
      props.fileShare(data)
      setMessages((messages) => [
        ...messages,
        {
          ...edata,
          attachmentFilePath: selectedFile[0].name,
          message: null,
        },
      ])
      setSelectedFile({})
    }
  }
	const sendMessage = ()=>{
		setTypeValue('')
		if (
			userInfo.fromUserId === userData.userId)
		  {
			const data = {
			  referenceId:new Date().getTime().toString(),
			  fromUserClinicId: userData.clinicId,
			  toUserClinicId:userInfo.toUserClinicId,
			  fromUserId: userData.userId,
			  toUserId:userInfo.toUserId,
			  fromUserType:userData.roleName,
			  toUserType:userInfo.toUserType,
			  fromUserName: userData.firstName + ' ' + userData.lastName,
			  toUserName:userInfo.toUserName,
			  message: typeValue,
			  appointmentId:0,
			  conversationSessionId:userInfo.conversationSessionId?userInfo.conversationSessionId:""
			}
			if(typeValue){
				chatSocket.emit('user_conversation',data)
				}
			sendFiles(data)
	}else{

		const data = {
			referenceId:new Date().getTime().toString(),
			fromUserClinicId: userData.clinicId,
			toUserClinicId:userInfo.fromUserClinicId,
			fromUserId: userData.userId,
			toUserId:userInfo.fromUserId,
			fromUserType:userData.roleName,
			toUserType:userInfo.fromUserType,
			fromUserName:userData.firstName + ' ' + userData.lastName,
			toUserName:userInfo.fromUserName,
			message: typeValue,
			appointmentId:0,
			conversationSessionId:userInfo.conversationSessionId?userInfo.conversationSessionId:""
		  }
		  if(typeValue){
		  chatSocket.emit('user_conversation',data)
		  }
		  sendFiles(data)
	}
}

	const messageHandler = (data) => {
		let messagess = []
		if (
			data.fromUserId === userData.userId ||
			data.conversationSessionId === userInfo.conversationSessionId
			) {
				setUserInfo(data)
		  let newdata = {
			...data,
			messageDateTime: TimestamptoTime(data.messageSentOnDateTime),
		  }
		  messages.forEach((x) => {
			if (
			  x.referenceId &&
			  x.referenceId === data.referenceId
			) {
			} else {
			  messagess.push(x)
			}
		  })
		  messagess.push(newdata)
		  setMessages(messagess)
		  if (data.fromUserId !== parseInt(userData.userId)) {
			chatSocket.emit('user_message_status', {
			  fromUserType:'INTERNAL_SUPPORT',
			  messageStatus: 2,
			  conversationId: data.conversationId,
			})
		  }
		}
	  }
	  useEffect(()=>{
		setStart(1)
		setMessages([])
        props.messageHistory({
			conversationSessionId:props.userInfo.conversationSessionId,
			start:1,
			limit:19
		})
		setUserInfo(props.userInfo)
	  },[props.userInfo])
	  useEffect(()=>{
		if(
			props.chatData.messageHistory&&
			props.chatData.messageHistory.response){
			let data=props.chatData.messageHistory.response.data
				if(data&&data.length>0){
					let messagess=[]
					data.forEach((x) => {
						messagess.push({
						  ...x,
						  messageDateTime: TimestamptoTime(x.messageSentOnDateTime),
						})
					})
					setMessages(messagess)
					setStart((startOne) => startOne + 1)
					if (startOne === 1) {
						scrollToBottom(100)
					  }else{
						       // ** Works second and all other times when previous messages are being loaded **
							   messages.map((x) => {
								messagess.push(x)
							  })
							  setMessages([...messagess])
							  /* function: to scroll the scrollbar to the middle when previous messages are fetched and loaded */
							  scrollToMiddle()
					  }
				}
			}
	  },[props.chatData.messageHistory])


	  
  const handleFileUpload = (event) => {
    let e = event.target.files
    let files = selectedFile
    let length = Object.keys(files).length
    Object.keys(e).forEach((x, i) => {
      files[length + i] = e[x]
    })
    setSelectedFile({ ...files })
  }

  useEffect(() => {
    if (props.chatData && props.chatData.file && props.chatData.file.response) {
      let blobData = props.chatData.file.response
      let fileName = props.chatData.fileType
      let fileType = props.chatData.fileType.split('.')[1]
      download(blobData, fileName, fileType)
    }
  }, [props.chatData.file])

  const download = (content, fileName, contentType) => {
    if (!contentType) contentType = 'application/octet-stream'
    var a = document.createElement('a')
    var blob = new Blob([content], { type: contentType })
    a.href = URL.createObjectURL(blob)
    a.download = fileName
    a.click()
    blob = null
  }

  const userAssignHandler = (data) =>{
		if (data.conversationSessionId === userInfo.conversationSessionId) {
		 setUserInfo(data)
		  }
  }

	return (
		<div className='chatTab__chatbox'>
		<div className='chatTab__chatbox__header'>
			<div className='chat__queueItem__cardCircle user--doctor'>
				{props.userInfo.fromUserType==='FRONTDESK'&&<img src={FrontDeskAvatar} alt='' />}
				{props.userInfo.fromUserType==='PHYSICIAN'&&<img src={DoctorAvatar} alt='' />}
				{props.userInfo.fromUserType==='MEDICAL_ASSISTANT'&&<img src={NurseAvatar} alt='' />}
				{props.userInfo.toUserType==='FRONTDESK'&&<img src={FrontDeskAvatar} alt='' />}
				{props.userInfo.toUserType==='PHYSICIAN'&&<img src={DoctorAvatar} alt='' />}
				{props.userInfo.toUserType==='MEDICAL_ASSISTANT'&&<img src={NurseAvatar} alt='' />}
			</div>

			<div className='chat__queueItem__cardCircle--flexWrapper'>
				<div className='chat__queueItem__cardContent--name'>
				{props.userInfo.fromUserId === userData.userId
                 ?  props.userInfo.toUserName
                 : props.userInfo.fromUserName}
				</div>
			</div>
		</div>

		<div
			onScroll={(e) => handleScroll(e)}
			className='participant__chat__list'
			ref={messageContainerRef}
		>
			<div className='participant__chat__listItems'>
			
			{messages.map((x, i) => {
              return x.fromUserId === userData.userId?(
				<div key={i}>
		
		{x.messageSentOnDateTime &&
                    messages[i - 1] &&
                    dateTommddyyy(x.messageSentOnDateTime) !==
                      dateTommddyyy(messages[i - 1].messageSentOnDateTime) && (
				<div className='chat__box__date'>
						<div className='chat__box__date--line'></div>
						<div className='chat__box__date--title'>
							<Typography variant='body2'>
							{months[new Date(x.messageSentOnDateTime).getMonth()] +
                          '  ' +
                          new Date(x.messageSentOnDateTime).getDate() +
                          ', ' +
                          new Date(x.messageSentOnDateTime).getFullYear()}
							</Typography>
						</div>
						<div className='chat__box__date--line'></div>
					</div>)}
					{i + 1 === 1 && (
						<div className='chat__box__date'>
						<div className='chat__box__date--line'></div>
						<div className='chat__box__date--title'>
							<Typography variant='body2'>
							{months[new Date(x.messageSentOnDateTime).getMonth()] +
                          '  ' +
                          new Date(x.messageSentOnDateTime).getDate() +
                          ', ' +
                          new Date(x.messageSentOnDateTime).getFullYear()}
							</Typography>
						</div>
						<div className='chat__box__date--line'></div>
					</div>

					)}
					<div
						className='participant__chatbox participant__chatbox--doctor'
						// key={i}
					>
						<div className='participant__chatbox--wrapper'>
							<div className='participant__chatbox--wrapper--flexEnd'>
								<div className='participant__chatboxHeader'></div>

								<div className='participant__chatboxMessage--wrapper '>
									<div className='participant__chatboxTime--side'>
										{x.messageDateTime}
									</div>
									<div className='participant__chatboxMessage '>
										{x.attachmentFilePath&&<div className='participant__fileUploadMessage' onClick={()=>props.fileDownload(x.attachmentFilePath)}>
											<img src={DownloadIcon} alt='' />

											<Typography variant='body2'>{x.attachmentFilePath}</Typography>
										</div>}
										{x.message&&<Typography variant='body2'>{x.message}</Typography>}
										{x.messageStatus===1&&
										<img
											src={MessageDeliveredIcon}
											alt='msg-delivered-icon'
											className='msg-delivered-icon'
										/>}
                                     {x.messageStatus===2&&
										<img
											src={MessageReadIcon}
											alt='msg-read-icon'
											className='msg-read-icon'
										/>}
                                     {x.messageStatus===0&&
										<img
											src={MessageSentIcon}
											alt='msg-sent-icon'
											className='msg-sent-icon'
										/>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				) : (
				<div key={i}>
			{x.messageSentOnDateTime &&messages[i - 1] &&
             dateTommddyyy(x.messageSentOnDateTime) !==
                dateTommddyyy(messages[i - 1].messageSentOnDateTime) && (
				<div className='chat__box__date'>
						<div className='chat__box__date--line'></div>
						<div className='chat__box__date--title'>
							<Typography variant='body2'>
							{months[new Date(x.messageSentOnDateTime).getMonth()] +
                          '  ' +
                          new Date(x.messageSentOnDateTime).getDate() +
                          ', ' +
                          new Date(x.messageSentOnDateTime).getFullYear()}
							</Typography>
						</div>

						<div className='chat__box__date--line'></div>
					</div>)}
					{i + 1 === 1 && (
				<div className='chat__box__date'>
						<div className='chat__box__date--line'></div>
						<div className='chat__box__date--title'>
							<Typography variant='body2'>
							{months[new Date(x.messageSentOnDateTime).getMonth()] +
                          '  ' +
                          new Date(x.messageSentOnDateTime).getDate() +
                          ', ' +
                          new Date(x.messageSentOnDateTime).getFullYear()}
								
							</Typography>
						</div>

						<div className='chat__box__date--line'></div>
					</div>)}
					<div
						className='participant__chatbox participant__chatbox--patient'
						// key={i}
					>
						<div>
							<div className='participant__chatboxHeader'></div>
							<div className='participant__chatboxMessage--wrapper participant__chatboxMessage--wrapperPatient'>
								<div className='participant__chatboxMessage participant__chatboxMessage--patient'>
									{x.attachmentFilePath&&<div className='participant__fileUploadMessage patient' onClick={()=>props.fileDownload(x.attachmentFilePath)}>
										<img src={DownloadIconBlue} alt='' />
										{/* <p>{x.attachmentFilePath}</p> */}
										<Typography variant='body2'>{x.attachmentFilePath}</Typography>
									</div>}
									{x.message&&<Typography variant='body2'>{x.message}</Typography>}
								</div>
								<div className='participant__chatboxTime--side'>{x.messageDateTime}</div>
							</div>
						</div>
					</div>
				</div>
                )
			})}
			</div>
			<div ref={messagesEndRef} />
		</div>

		<div className='bottomInputField__wrapper'>
		<div className="fileuploader__wrapper">
          {selectedFile &&
            Object.keys(selectedFile).map((x) => (
              <div className="fileuploader__preview">
                {selectedFile[x].type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                  <img src={WordDocIcon} alt="" />
                ) : selectedFile[x].type === 'application/msword' ? (
                  <img src={WordDocIcon} alt="" />
                ) : selectedFile[x].type === 'docx' ? (
                  <img src={WordDocIcon} alt="" />
                ) : selectedFile[x].type === 'image/png' ? (
                  <img src={ImageIcon} alt="" />
                ) : selectedFile[x].type === 'image/jpeg' ? (
                  <img src={ImageIcon} alt="" />
                ) : selectedFile[x].type === 'video/mp4' ? (
                  <img src={VideoIcon} alt="" />
                ) : selectedFile[x].type === 'application/pdf' ? (
                  <img src={PdfIcon} alt="" />
                ) : (
                  <img src={FileIcon} alt="" />
                )}

                <div className="fileuploader__textContent">
                  <div className="name">{selectedFile[x].name}</div>
                  <span className="type">
                    {selectedFile[x].type ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      ? 'DOC'
                      : selectedFile[x].type === 'application/msword'
                      ? 'DOC'
                      : selectedFile[x].type === 'docx'
                      ? 'DOC'
                      : selectedFile[x].type === 'image/png'
                      ? 'PNG'
                      : selectedFile[x].type === 'image/jpeg'
                      ? 'JPG'
                      : selectedFile[x].type === 'video/mp4'
                      ? 'MP4'
                      : selectedFile[x].type === 'application/pdf'
                      ? 'PDF'
                      : selectedFile[x].type}
                  </span>
                </div>
                <img
                  src={CloseIconRounded}
                  className="close-icon-rounded"
                  alt=""
                  onClick={() => setSelectedFile({})}
                />
                {/* <span
                  className="fileuploader__progressbar"
                  style={{ width: '70%' }}
                ></span> */}
              </div>
            ))}
        </div>
			{/* !!!!!!!!!!!! ADD fileUpload--wrapper content part here !!!!!!!!!!!!!!!!! */}
			<div className='participant__chatBottom--chatTab'>
				{userInfo&&userInfo.toUserId!==0&&<div className='participant__chatBottomMsgBox disabled'>
					<input
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								sendMessage();
							}
						}}
						value={typeValue}
						type='text'
						placeholder='Send a message'
						required
						onChange={(e) => setTypeValue(e.target.value)}
					/>
					<IconButton>
						<input
							value={''}
							type="file"
							id="upload"
							hidden
							onChange={handleFileUpload}
						/>
						<label
						 htmlFor='upload' 
						 className='upload__label'
						 >
							<AttachFileRoundedIcon />
						</label>
					</IconButton>

					<IconButton
						aria-label='send message'
						onClick={sendMessage}
					>
						<SendIcon />
					</IconButton>
				</div>}
				<div className='participant__chatBottomOptions'>
					<div className='participant__chatBottomOptions--btn'>
						<p onClick={handleAssignElClick}>Assign</p>
						<Popover
							id={assignElId}
							open={assignElOpen}
							anchorEl={assignEl}
							onClose={handleAssignElClose}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
						>
							<div className='chatBottomOptions__popover'>
								<div className='chatBottomOptions__heading'>
									<p>Assign</p>
								</div>
								<FormControl component='fieldset'>
									<RadioGroup
										aria-label='Assign'
										name='Physician'
										value={selectedUser && selectedUser.userId}
									>
										 {internalUsers.map((x, i) => (
										<div className='chatBottomOptions__popover--LabelFlex' key={i}>
											<FormControlLabel
												onClick={() => handleUser(x)}
												value={x.userId}
												control={<Radio color='primary' />}
												label={x.userId===userData.userId?'You':x.fullName}
											/>
											 <span>
														<div className='chat__queueItem__cardRole role--admin'>
															<div className='rolename'>Internal Support</div>
														</div>
												</span> 
										</div>
										 ))}
									</RadioGroup>
								</FormControl>
								<div className='confirmAssignment__popUp--btnWrapper'>
									<Button
										// variant="outlined"
										color='primary'
										onClick={handleAssignElClose}
									>
										Cancel
									</Button>
									<Button
										variant='contained'
										color='primary'
										onClick={() =>
											props.assignUser(userInfo, selectedUser)
										  }
									>
										Assign
									</Button>
								</div>
							</div>
						</Popover>
					</div>

					<div className='participant__chatBottomOptions--btn'>
						<p
						onClick={handleOpenClick}
						>
						{userInfo&&userInfo.isSessionClosed === false ? 'Close' : 'Open'}
						</p>
						<Popover
							id={closeElId}
							open={closeElOpen}
							anchorEl={closeEl}
							onClose={handleCloseClick}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
						>
							<div className='confirmAssignment__popUp'>
								<div className='confirmAssignment__popUp--title'>
									<p>
									{`Are you sure you want move this chat to the ${
                                     userInfo&&userInfo.isSessionClosed === false
                                      ? 'Closed'
                                      : 'Opened'
                                    } list?`}
									</p>
								</div>
								<div className='confirmAssignment__popUp--btnWrapper'>
									<Button
										// variant="outlined"
										color='primary'
										onClick={handleCloseClick}
									>
										No
									</Button>
									<Button
										onClick={() => props.closeChat(userInfo)}
										variant='contained'
										color='primary'
									>
										Yes
									</Button>
								</div>
							</div>
						</Popover>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
}

const mapStateToProps = (state) => ({
	chatData: state.chat,
  })
  
  const mapDispatchToProps = (dispatch) => ({
	messageHistory: (values) => dispatch(messageHistory(values)),
	fileShare:(values) => dispatch(fileShare(values)),
	fileDownload:(values) => dispatch(fileDownload(values)),
  })
  ChatBox.propTypes = {
	messageHistory: PropTypes.func,
	fileShare: PropTypes.func,
	fileDownload:PropTypes.func,
  }
  export default connect(mapStateToProps, mapDispatchToProps)(ChatBox)
