import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {inboxList} from 'Redux/Chat/chat.actions'
import Header from '../Header';
import Select from 'react-select';
import { chatSocket } from 'Utils/web-socket'
import { Typography } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import SearchIcon from '@material-ui/icons/Search';
import NewMsgTag from '../../assets/icons/newmsg-tag.svg';
import TrilliumLogo from '../../assets/icons/trillium-logo-single.png';
import DoctorAvatar from '../../assets/icons/doctor-avatar.svg';
import NurseAvatar from '../../assets/icons/nurse-avatar.svg';
import FrontDeskAvatar from '../../assets/icons/frontdesk-avatar.svg';
import InputBase from '@material-ui/core/InputBase';
import ClearIcon from '../../assets/icons/clear.svg';
import { makeStyles } from '@material-ui/core/styles';
import { dateTommddyyy } from 'Helpers/TimeDateTimestamp'
import { TimestamptoTime } from 'Helpers/TimeDateTimestamp'
import ChatBox from './ChatBox';

import './styles.css';

/**
 * @fileoverview ChatTab Component - Contains Inbox and ChatBox
 * @author  Akhil Francis <akhil@tensaw.email>
 * @author  Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <ChatTab />
 * )
 */

 const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

//******************************************************

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#e9e9e9',
		height: '40px',
		'&:hover': {
			backgroundColor: '#F2F2F2',
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
		height: '100%',
		width: '100%',
		'&.Mui-focused': {
			border: '2px solid var(--primary-green)',
			borderRadius: '4px',
		},
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
	},
}));

function Chat(props) {
	let userData = JSON.parse(localStorage.getItem('user_data'))
	const [clickedUser, setClickedUser] = useState(null)
	const [chatQueItems, setChatQueItems] = useState([])
	const [messageStatus,setMessageStatus]=useState(true)
	const [chatView,setChatView]=useState("Clinic")
	const [internalUsers] = useState(JSON.parse(localStorage.getItem("internalUsers")))
	const classes = useStyles();
	  //websocket events related to chat
	  useEffect(() => {
		chatSocket.removeListener('clinic_login', clinicLoginResponse)
		chatSocket.removeListener('user_conversation', messageHandler)
		chatSocket.removeListener('user_session_assign', userAssignHandler)
		chatSocket.removeListener('user_session_close', closedChatInfo)
		chatSocket.on('clinic_login', clinicLoginResponse)
		chatSocket.on('user_conversation', messageHandler)
		chatSocket.on('user_session_assign', userAssignHandler)
		chatSocket.on('user_session_close', closedChatInfo)
		return () => {
		  chatSocket.removeListener('clinic_login', clinicLoginResponse)
		  chatSocket.removeListener('user_conversation', messageHandler)
		  chatSocket.removeListener('user_session_assign', userAssignHandler)
		  chatSocket.removeListener('user_session_close', closedChatInfo)
		}
	  },[chatQueItems])

	  //converting date in inbox to weekdays and time
	  const getInboxDate = (e) => {
		let yesterday = new Date()
		let x = new Date(e)
		let diffDays = new Date().getDate() - x.getDate()
		yesterday.setDate(yesterday.getDate() - 1)
		let today = new Date()
		if (dateTommddyyy(x) === dateTommddyyy(today)) {
		  let time = TimestamptoTime(x)
		  return time
		} else if (diffDays > 6) {
		  return `${x.getDate()} ${monthNames[x.getMonth()]} ${x.getFullYear()}`
		} else if (dateTommddyyy(x) === dateTommddyyy(yesterday)) {
		  return `yesterday`
		} else {
		  return weekdays[x.getDay()]
		}
	  }

	useEffect(() => {
		setTimeout(() => {
		  chatSocket.emit('clinic_login', {
			roleId:userData.roleId,
			userId: userData.userId,
			clinicId: userData.clinicId,
			userName:userData.firstName
		  })
		}, 1000)
		props.inboxList({isClosed:!messageStatus})
	  }, [])

   const clinicLoginResponse = (data) =>{
	//   console.log(data)
   }
   const messageHandler = (data) => {
    if (data.fromUserId !== userData.userId) {
      chatSocket.emit('user_message_status', {
        fromUserType:'INTERNAL_SUPPORT',
        messageStatus: 1,
        conversationId: data.conversationId,
      })
    }
    let newarr = [
      {
        ...data,
        lastMessage: data.message ? data.message : data.attachmentFilePath,
        lastMessageStatus:
          clickedUser &&
          clickedUser.conversationSessionId === data.conversationSessionId
            ? 2
            : 1,
        inboxDate: getInboxDate(data.messageSentOnDateTime)
	  }
    ]
    chatQueItems.forEach((x) => {
      if ((data.fromUserId+data.toUserId) !== (x.fromUserId+x.toUserId)) {
        newarr.push(x)
      }
    })
    setChatQueItems(newarr)
  }
  useEffect(()=>{
	if(
		props.chatData.inboxList&&
		props.chatData.inboxList.response){
		let code=props.chatData.inboxList.response.responseCode
		if(code===0){
		let arr = props.chatData.inboxList.response.data.map((x) => {
			return {
			  ...x,
			  inboxDate: getInboxDate(x.lastMessageCreatedDateTime),
			}
		  })
		  let arr2=arr.reverse()
		  internalUsers.forEach((x) => {
			const map1 = arr2.filter(y => (y.fromUserId+y.toUserId)===(userData.userId+x.userId));
			if(map1.length===0&&userData.userId!==x.userId){
			arr2.push({
			  fromUserClinicId: userData.clinicId,
			  fromUserName: userData.firstName + ' ' + userData.lastName,
			  fromUserId: userData.userId,
			  fromUserType:userData.roleName,
			  toUserId: x.userId,
			  toUserName: x.fullName,
			  toUserType:x.roleName,
			  toUserClinicId:x.clinicId,
			})
			}
		})
		  setChatQueItems(arr2)
	   }
	}

  },[props.chatData.inboxList])


  const setClickedUserData = (x) => {
    setClickedUser(x)
    let newarr = []
    chatQueItems.map((y) => {
      if (x.conversationSessionId === y.conversationSessionId) {
        newarr.push({
          ...y,
          lastMessageStatus: 2,
        })
      } else {
        newarr.push(y)
      }

      setChatQueItems(newarr)
    })
  }
  const assignUser = (userinfo, assignedUser) => {
    if (userinfo.fromUserId === userData.userId) {
		chatSocket.emit('user_session_assign', {
		  fromUserClinicId: userinfo.toUserClinicId,
		  toUserClinicId: assignedUser.clinicId,
		  fromUserId: userinfo.toUserId,
		  fromUserName: userinfo.toUserName,
		  toUserId: assignedUser.userId,
		  fromUserType: userinfo.toUserType,
		  toUserType:assignedUser.roleName,
		  toUserName: assignedUser.fullName,
		  loggedInUserId: userData.userId,
		  loggedInUserType:userData.roleName,
		  loggedInUserClinicId: userData.clinicId,
		  loggedInUserName: userData.firstName + ' ' + userData.lastName,
		  conversationSessionId: userinfo.conversationSessionId
			? userinfo.conversationSessionId
			: '',
		})
	  } else {
		chatSocket.emit('user_session_assign', {
		  fromUserClinicId: userinfo.fromUserClinicId,
		  toUserClinicId: assignedUser.clinicId,
		  fromUserId: userinfo.fromUserId,
		  fromUserName: userinfo.fromUserName,
		  toUserId: assignedUser.userId,
		  fromUserType: userinfo.fromUserType,
		  toUserType:assignedUser.roleName,
		  toUserName: assignedUser.fullName,
		  loggedInUserId: userData.userId,
		  loggedInUserType:userData.roleName,
		  loggedInUserClinicId: userData.clinicId,
		  loggedInUserName: userData.firstName + ' ' + userData.lastName,
		  conversationSessionId: userinfo.conversationSessionId
			? userinfo.conversationSessionId
			: '',
		})
	  }
  }
  const userAssignHandler = (data) => {
    if (data.toUserId === userData.userId) {
      let newarr = [
        {
          ...data,
          inboxDate: getInboxDate(data.messageSentOnDateTime),
          lastMessage: data.message ? data.message : data.attachmentFilePath,
        },
      ]
      chatQueItems.forEach((x) => {
        if (data.conversationSessionId !== x.conversationSessionId) {
          newarr.push(x)
        }
      })
      setChatQueItems(newarr)
    } else {
      let newArr = []
      chatQueItems.forEach((x) => {
        if (data.conversationSessionId !== x.conversationSessionId) {
          newArr.push(x)
        }
      })
      setChatQueItems(newArr)
      setClickedUser(null)
    }
  }
    //onclick function to emit closing user chat information to socket
	const closeChat = (x) => {
		chatSocket.emit('user_session_close', {
		  conversationSessionId: x.conversationSessionId,
		  isSessionClosed: x.isSessionClosed === true ? false : true,
		  fromUserClinicId: userData.clinicId,
		})
	  }

	  //closed event listener to update inbox
	  const closedChatInfo = (data) => {
		let filtered = []
		chatQueItems.forEach((x) => {
		  if (x.conversationSessionId !== data.conversationSessionId) {
			filtered.push(x)
		  }
		})
		setChatQueItems([...filtered])
		setClickedUser(null)
	  }

	const chatFilter = (e)=>{
		setMessageStatus(e)
		props.inboxList({isClosed:!e})
	}

	return (
		<div className='chatTab--wrapper'>
			<Header />
			<div className='chatTab'>
				<div className='chatTab__inbox'>
					<div className='chatTab__inbox__header--flex'>
						<div className='chatTab__inbox__header'>
							{/* <p>Inbox</p> */}
							<Typography variant='h6'>Inbox</Typography>
						</div>

						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<div className='searchInput--wrapper'>
								<InputBase
									type='text'
									placeholder='Search…'
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
									}}
									inputProps={{ 'aria-label': 'search' }}
								/>
								<div className='searchInput--clear'>
									<img src={ClearIcon} alt='' />
								</div>
							</div>
						</div>

						<div className='chatTab__inbox__options'>
							<div className='chatTab--flex'>
								<div className='chatTab__inbox__options--Switch '>
									<div className={messageStatus?'switch switch__open active':'switch switch__open closed'} onClick={()=>chatFilter(true)}>Open</div>
									<div className={messageStatus?'switch switch__open closed':'switch switch__open active'} onClick={()=>chatFilter(false)}>Closed</div>
								</div>
								<div className='chatTab__inbox__options--Switch '>
									<div className={`switch switch__open ${chatView==="Clinic"?"active":"closed"}`} onClick={()=>setChatView("Clinic")}>Clinic</div>
									<div className={`switch switch__open ${chatView==="Staff"?"active":"closed"}`}  onClick={()=>setChatView("Staff")}>Staff</div>
								</div>
							</div>

							{/* Have used Select component from 'React-select' here instead of the one from Material-UI */}
							{/* <Select
								options={users}
								// onChange={userSelection}
								// value={inboxUserId}
								className='react-select'
								classNamePrefix='react-select-inner'
								placeholder='Select'
								id='chatTab__inbox__selectPhysician'
							/> */}
						</div>
					</div>

					<div className='chatTab__chatList scrollbarY--custom'> 
					{chatView === 'Staff' && (
						<div className='chat__queueItems' >
						{chatQueItems.map((x,i)=>{
							 return x.fromUserType === 'INTERNAL_SUPPORT' &&
							 x.toUserType === 'INTERNAL_SUPPORT' ? (
							<div  key={i} className='chat__queueItem' onClick={()=>setClickedUserData(x)}>
								<div className='chat__queueItem__card'>
									<div className='chat__queueItem__cardCircle user--doctor'>
									{x.fromUserType==='FRONTDESK'&&<img src={FrontDeskAvatar} alt='' />}
				                    {x.fromUserType==='PHYSICIAN'&&<img src={DoctorAvatar} alt='' />}
				                    {x.fromUserType==='MEDICAL_ASSISTANT'&&<img src={NurseAvatar} alt='' />}
				                    {x.toUserType==='FRONTDESK'&&<img src={FrontDeskAvatar} alt='' />}
				                    {x.toUserType==='PHYSICIAN'&&<img src={DoctorAvatar} alt='' />}
				                    {x.toUserType==='MEDICAL_ASSISTANT'&&<img src={NurseAvatar} alt='' />}
										{/* <span className='chat__statusIndicator'></span> */}
									</div>
									<div className='chat__queueItem__cardContent'>
										<div className='chat__queueItem__cardContent--header'>
											<div className='chat--flex'>
												<div className='chat__queueItem__cardContent--name chat--flex'>
													<span>{x.fromUserId === userData.userId
                                                       ? x.toUserName
                                                       : x.fromUserName}
													   </span>
												</div>
											</div>
											<div className='chat__queueItem__cardContent--date '>
											{x.lastMessageStatus !== 2 &&
                                                  x.fromUserId !== userData.userId && (
												<img
													src={NewMsgTag}
													className='chat__queueItem__cardContent--newTag'
													alt=''
												/>)}
												<span>{x.inboxDate}</span>
											</div>
										</div>
										<div className='chat__queueItem__cardContent--clinicName'>
										{x.fromUserId === userData.userId
                                                       ? x.toUserClinicName
                                                       : x.fromUserClinicName}
										</div>
										<div
											className='chat__queueItem__cardContent--msgPreview'
											// className='chat__queueItem__cardContent--msgPreviewUnread'
										>
											{x.lastMessage}
										</div>
									</div>
								</div>
							</div>
							  ) : null
						})}
						</div>
					)}

           {chatView === 'Clinic' && (
						<div className='chat__queueItems' >
						{chatQueItems.map((x,i)=>{
							 return ((x.fromUserType === 'INTERNAL_SUPPORT'&& x.toUserType !== 'INTERNAL_SUPPORT') ||
							( x.fromUserType !== 'INTERNAL_SUPPORT'&& x.toUserType === 'INTERNAL_SUPPORT')) ? (
							<div  key={i} className='chat__queueItem' onClick={()=>setClickedUserData(x)}>
								<div className='chat__queueItem__card'>
									<div className='chat__queueItem__cardCircle user--doctor'>
									{x.fromUserType==='FRONTDESK'&&<img src={FrontDeskAvatar} alt='' />}
				                    {x.fromUserType==='PHYSICIAN'&&<img src={DoctorAvatar} alt='' />}
				                    {x.fromUserType==='MEDICAL_ASSISTANT'&&<img src={NurseAvatar} alt='' />}
				                    {x.toUserType==='FRONTDESK'&&<img src={FrontDeskAvatar} alt='' />}
				                    {x.toUserType==='PHYSICIAN'&&<img src={DoctorAvatar} alt='' />}
				                    {x.toUserType==='MEDICAL_ASSISTANT'&&<img src={NurseAvatar} alt='' />}
										{/* <span className='chat__statusIndicator'></span> */}
									</div>
									<div className='chat__queueItem__cardContent'>
										<div className='chat__queueItem__cardContent--header'>
											<div className='chat--flex'>
												<div className='chat__queueItem__cardContent--name chat--flex'>
													<span>{x.fromUserId === userData.userId
                                                       ? x.toUserName
                                                       : x.fromUserName}
													   </span>
												</div>
											</div>
											<div className='chat__queueItem__cardContent--date '>
											{x.lastMessageStatus !== 2 &&
                                                  x.fromUserId !== userData.userId && (
												<img
													src={NewMsgTag}
													className='chat__queueItem__cardContent--newTag'
													alt=''
												/>)}
												<span>{x.inboxDate}</span>
											</div>
										</div>
										<div className='chat__queueItem__cardContent--clinicName'>
										{x.fromUserId === userData.userId
                                                       ? x.toUserClinicName
                                                       : x.fromUserClinicName}
										</div>
										<div
											className='chat__queueItem__cardContent--msgPreview'
											// className='chat__queueItem__cardContent--msgPreviewUnread'
										>
											{x.lastMessage}
										</div>
									</div>
								</div>
							</div>
							  ) : null
						})}
						</div>
					)}
					</div>
				</div>
				{/* <div className='chatTab__newMessage'>
					<div className='chatTab__newMessage__header--flex '>
						<div className='chatTab__newMessage__header'>
							<div className='chatTab__inbox__headerBtnWrapper'>
								<ArrowBackIos />
							</div>
							<p>New Message</p>
						</div>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<div className='searchInput--wrapper'>
								<InputBase
									type='text'
								
									placeholder='Search…'
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
									}}
									inputProps={{ 'aria-label': 'search' }}
									
								/>
								<div className='searchInput--clear'>
									<img
										
										src={ClearIcon}
										alt=''
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='chatTab__newMessage__searchItemList scrollbarY--custom'>
						<div
							className='chatTab__newMessage__searchItem '
							
						>
							<div className='chat__queueItem__cardCircle'>
								<span className="chat__statusIndicator"></span>
							</div>
							<div className='chat__queueItem__cardCircle--wrapper'>
								<div className='chat__queueItem__cardHeader'>
									<div className='chat__queueItem__cardName'>
										Mackeysey
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}

				{/* **************** Default View - No new messages *****************/}
				{clickedUser ? (
				<ChatBox
				 closeChat={(x) => closeChat(x)}
				 userInfo={clickedUser}
				 assignUser={(userinfo, assignedUser) =>
					assignUser(userinfo, assignedUser)
				  }
				/>
				) : (
           <div className='chatBox__welcomeView'>
					<div className='chatBox__welcomeView--wrapper'>
						<img alt={'TrilliumLogo'} src={TrilliumLogo} />
						<Typography>Trillium Chat</Typography>
						<span>You have no conversations.</span>
						<span>Search for a user to start chatting.</span>
					</div>
				</div>
              )}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	chatData: state.chat,
  })
  
  const mapDispatchToProps = (dispatch) => ({
	inboxList: (values) => dispatch(inboxList(values)),
  })
  Chat.propTypes = {
	inboxList: PropTypes.func,
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Chat)
