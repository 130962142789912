import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './style.css';

/**
 * @fileOverview Manages Routing.
 * @component
 * @author Akhil Francis <akhil@tensaw.email>
 * @author Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <Header/>
 * )
 */

const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: '2rem !important',
	},
	popover: {
		pointerEvents: 'none',
	},
	popoverContent: {
		pointerEvents: 'auto',
	},
}));

function Header(props) {
	const classes = useStyles();
	const [openedPopover, setOpenedPopover] = useState(false);
	const popoverAnchor = useRef(null);
	const [newMenuName, setNewMenuName] = useState('');

	const popoverEnter = ({ currentTarget }) => {
		setOpenedPopover(true);
	};

	const popoverLeave = ({ currentTarget }) => {
		setOpenedPopover(false);
	};
const logOutUser = () =>{
     window.location.href="/login"
}
	return (
		<div className='clinic__header'>
			<div className='clinic__header--inner header-left'>
				<ul>
					<li className={newMenuName === 'dashboard' ? 'selected' : null}>
						Chat
					</li>
				</ul>
			</div>
			<div
				className='clinic__header--inner header-right'
				onMouseEnter={popoverEnter}
				onMouseLeave={popoverLeave}
			>
				<div
					className='clinic__profileCircle'
					ref={popoverAnchor}
					aria-owns='mouse-over-popover'
					aria-haspopup='true'
				>
					<span>IS</span>
				</div>
				<KeyboardArrowDownIcon color='primary' />
			</div>
			<Popover
				id='mouse-over-popover'
				className={classes.popover}
				classes={{
					paper: classes.popoverContent,
				}}
				open={openedPopover}
				anchorEl={popoverAnchor.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
			>
				<div className='clinicHeader__popover'>
					<div className='clinicHeader__popover--name'>Internal Support</div>
					<div className='clinicHeader__popover--list'>
						<div className='logout' onClick={()=>logOutUser()}>Logout</div>
					</div>
				</div>
			</Popover>
		</div>
	);
}

export default Header;
